import { Icon, IconsEnum } from 'components/Icons';
import { Text } from 'components/lingUI';
import { selectIsActiveAvatars } from 'features/configuration/selectors';
import { selectAvatar } from 'features/dashboard/selectors';
import { useTranslation } from 'hooks/useLingUI';
import { useTypedSelector } from 'lib/centralStore';
import Image from 'next/image';
import { useState } from 'react';
import { ItemWithTranslation } from 'types/swagger';
import { capitalize } from 'utility/functions';
import { Wrapper } from '../wrapper';
import styles from './HeaderTitle.module.scss';

export type HeaderTitleProps = ItemWithTranslation<{
  title: string;
  subtitle?: string;
  titleHighlighted?: string;
}> & {
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  showHeaderTitle?: boolean;
  className?: string;
  children?: React.ReactElement | React.ReactElement[];
  typeIcon?: IconsEnum | null;
  openDialogAvatar?: () => void;
};

export const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title,
  titleTrKey,
  subtitle,
  subtitleTrKey,
  titleHighlighted,
  titleHighlightedTrKey,
  noPaddingLeft,
  noPaddingRight,
  showHeaderTitle = true,
  className,
  children,
  typeIcon,
  openDialogAvatar,
}: HeaderTitleProps) => {
  const { t } = useTranslation();
  const avatar = useTypedSelector(selectAvatar);
  const [isHoverAvatar, setIsHoverAvatar] = useState(false);
  const isActiveFeatureAvatars = useTypedSelector(selectIsActiveAvatars);

  return showHeaderTitle || subtitle || children || typeIcon ? (
    <Wrapper
      bgColorTransparent={true}
      noPaddingBottom={true}
      noPaddingTop={true}
      noPaddingLeft={noPaddingLeft}
      noPaddingRight={noPaddingRight}
      className={className}
    >
      <div className={`${styles.flexSpaceBeetween} ${styles.container}`}>
        <div>
          {showHeaderTitle && (
            <div>
              <h1 className={styles.title}>
                <Text trKey={titleTrKey} fallback={title} />
                &nbsp;
                {titleHighlighted && (
                  <span className={styles.titleHighlighted}>
                    <Text trKey={titleHighlightedTrKey} fallback={titleHighlighted} onRender={capitalize} />
                  </span>
                )}
              </h1>
            </div>
          )}
          {subtitle && showHeaderTitle ? (
            <p className={styles.subtitle}>{t(subtitleTrKey, subtitle)}</p>
          ) : (
            subtitle && <p className={`${styles.onlySubtitle}`}>{t(subtitleTrKey, subtitle)}</p>
          )}
        </div>
        {children && children}
        {isActiveFeatureAvatars ? (
          typeIcon ? (
            avatar ? (
              <button
                className={`${styles.icon} ${styles.iconWithAvatar}`}
                onMouseEnter={() => setIsHoverAvatar(true)}
                onMouseLeave={() => setIsHoverAvatar(false)}
                onClick={() => openDialogAvatar && openDialogAvatar()}
              >
                <Image
                  src={avatar.src}
                  alt="avatar"
                  width={42}
                  height={42}
                  style={{ borderRadius: '50%', opacity: isHoverAvatar ? 0.4 : undefined }}
                />
                {isHoverAvatar && (
                  <div className={`${styles.overlayPencil}`}>
                    <Icon iconId={IconsEnum.PENCIL} color="var(--color-on-bg-primary)" />
                  </div>
                )}
              </button>
            ) : (
              <button
                className={`${styles.icon} ${styles.iconWithAvatar}`}
                onMouseEnter={() => setIsHoverAvatar(true)}
                onMouseLeave={() => setIsHoverAvatar(false)}
                onClick={() => openDialogAvatar && openDialogAvatar()}
              >
                <Icon iconId={typeIcon} className={styles.icon} style={{ opacity: isHoverAvatar ? 0.4 : undefined }} />

                {isHoverAvatar && (
                  <div className={`${styles.overlayPencil}`}>
                    <Icon iconId={IconsEnum.PENCIL} color="var(--color-on-bg-primary)" />
                  </div>
                )}
              </button>
            )
          ) : null
        ) : typeIcon ? (
          <div>
            <Icon iconId={typeIcon} className={styles.icon} />
          </div>
        ) : null}
      </div>
    </Wrapper>
  ) : null;
};
