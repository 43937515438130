import { Icon, IconsEnum } from 'components/Icons';
import { useCallback, useEffect, useState } from 'react';

import { AnchorLink } from 'components/anchorLink';
import { SimpleAccordion } from 'components/simpleAccordion';
import { isClientSide } from 'utility/functions';
import styles from './SidebarGeneric.module.scss';
import { useRouter } from 'next/router';

export type SidebarGenericProps = {
  title?: string;
  item: Array<SideNavItem>;
  parent?: string;
};

type SideNavItem = {
  title: string;
  href?: string;
  list?: {
    title: string;
    href: string;
  }[];
};

export const SidebarGeneric = ({ title, item, parent }: SidebarGenericProps) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isScrollOnTop, setIsScrollOnTop] = useState(false);
  const [y, setY] = useState(0);
  const [countItemToShow, setCountItemToShow] = useState(9);
  const router = useRouter();

  const onScrollHandler = useCallback(() => {
    if (isClientSide()) {
      if (y > window.scrollY) {
        setIsScrollOnTop(true);
      } else if (y < window.scrollY) {
        setIsScrollOnTop(false);
      }
      setY(window.scrollY);
    }
  }, [y]);

  useEffect(() => {
    if (isClientSide()) {
      window.addEventListener('scroll', onScrollHandler, { passive: true });
      setY(window.scrollY);
    }
    return () => {
      if (isClientSide()) {
        window.removeEventListener('scroll', onScrollHandler);
      }
    };
  }, [onScrollHandler]);

  return (
    <div className={`${styles.sticky} ${isScrollOnTop ? styles.stickyScrollOnTop : ''}`}>
      {title && (
        <button
          type="button"
          className={`${styles.btnOpenSidebar} ${styles.isMobile}`}
          onClick={() => setIsOpenSidebar(true)}
        >
          <span className={styles.title}>{title}</span>
          <Icon iconId={IconsEnum.LIST_MENU} className={styles.iconOpenSidebar} color="var(--color-on-bg-secondary)" />
        </button>
      )}
      <div className={`${styles.wrapper} ${isOpenSidebar ? styles.isOpenSidebar : ''}`}>
        {title && (
          <div className={styles.headingContainer}>
            <span className={styles.title}>{title}</span>
            <button type="button" className={`${styles.isMobile}`} onClick={() => setIsOpenSidebar(false)}>
              <Icon className={styles.iconCloseSidebar} iconId={IconsEnum.CLOSE} color="var(--color-on-bg-primary)" />
            </button>
          </div>
        )}
        <div className={styles.itemsContainer}>
          {item &&
            item.map((sideNavItem: SideNavItem, index) => {
              const isActiveLink = parent
                ? `/${parent}${sideNavItem.href}` === router.asPath
                : sideNavItem.href === router.asPath;
              let listItemToShow: {
                title: string;
                href: string;
              }[] = [];
              if (sideNavItem.list) {
                if (sideNavItem.list.length > countItemToShow) {
                  listItemToShow = sideNavItem.list.slice(0, countItemToShow + 1);
                } else {
                  listItemToShow = sideNavItem.list;
                }
              }
              return listItemToShow.length > 0 ? (
                <SimpleAccordion title={sideNavItem.title} layoutType={'sidenav'} key={index}>
                  {listItemToShow.map((sideNavItemAccordionItem, index) => {
                    const path = router.asPath.slice(1).split('/');
                    const pathAccordion = sideNavItemAccordionItem.href.slice(1).split('/');
                    const isActive = pathAccordion[pathAccordion.length - 1] === path[path.length - 1];

                    if (index >= countItemToShow) {
                      return (
                        <button
                          key={index}
                          className={styles.sideNavItemAccordionItem}
                          onClick={() => setCountItemToShow(countItemToShow + 9)}
                        >
                          <Icon
                            className={styles.iconPlus}
                            iconId={IconsEnum.PLUS_CIRCLE}
                            color="var(--color-primary)"
                          />
                          <span>Vedi di più</span>
                        </button>
                      );
                    }

                    return (
                      <AnchorLink
                        className={`${styles.sideNavItemAccordionItem} ${isActive ? styles.active : ''}`}
                        key={sideNavItemAccordionItem.title + '_' + index}
                        href={parent ? `/${parent}${sideNavItemAccordionItem.href}` : sideNavItemAccordionItem.href}
                      >
                        {sideNavItemAccordionItem.title}
                      </AnchorLink>
                    );
                  })}
                </SimpleAccordion>
              ) : (
                <AnchorLink
                  key={index}
                  className={`${styles.sideNavItem} ${isActiveLink ? styles.active : ''}`}
                  href={parent ? `/${parent}${sideNavItem.href}` : `${sideNavItem.href}`}
                >
                  {sideNavItem.title}
                </AnchorLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};
