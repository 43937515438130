import * as Accordion from '@radix-ui/react-accordion';
import { RichText } from 'components/richText';
import styles from './SimpleAccordionContent.module.scss';

export type SimpleAccordionContentProps = {
  children: React.ReactNode;
  setStateLayoutType?: SimpleAccordionContentLayoutType;
  containerClassName?: string;
  isInnerHtmlContent?: boolean;
};

export type SimpleAccordionContentLayoutType = 'main' | 'sidenav';

export const SimpleAccordionContent = ({
  children,
  setStateLayoutType,
  containerClassName,
  isInnerHtmlContent,
}: SimpleAccordionContentProps) => {
  return (
    <Accordion.Content
      className={`${styles.container} ${setStateLayoutType === 'sidenav' ? styles.sidenavLayout : ''} ${
        containerClassName ? containerClassName : ''
      }`}
    >
      {!isInnerHtmlContent ? (
        <div className={setStateLayoutType === 'sidenav' ? styles.sidenavLayout : styles.childrenContainer}>
          {children}
        </div>
      ) : (
        <RichText
          containerClassName={setStateLayoutType === 'sidenav' ? styles.sidenavLayout : styles.childrenContainer}
        >
          {children as string}
        </RichText>
      )}
    </Accordion.Content>
  );
};
