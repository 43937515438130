import * as Accordion from '@radix-ui/react-accordion';

import { useEffect, useState } from 'react';

import { IppicaNazioneImageDto } from 'types/swagger';
import { SimpleAccordionContent } from './simpleAccordionContent';
import { SimpleAccordionTrigger } from './simpleAccordionTrigger';
import classNames from 'classnames';
import { isTruthy } from 'utility/functions';
import styles from './SimpleAccordion.module.scss';

export interface AccordionBase {
  isOpen?: boolean;
  onToggle?: (_: boolean) => void;
}

export type SimpleAccordionProps = AccordionBase & {
  //   text?: string;
  title: string;
  image?: IppicaNazioneImageDto; // ImageProps & { srcFallback: string };
  children: React.ReactNode;
  className?: string;
  layoutType?: SimpleAccordionLayoutType;
  hasCheckbox?: boolean;
  isInnerHtmlContent?: boolean;
  triggerWrapperClassName?: string;
  contentWrapperClassName?: string;
  removeIcon?: boolean;
};

type SimpleAccordionLayoutType = 'main' | 'sidenav';

export const SimpleAccordion = ({
  //  text,
  image,
  title,
  isOpen,
  children,
  className,
  layoutType,
  hasCheckbox,
  isInnerHtmlContent,
  triggerWrapperClassName,
  contentWrapperClassName,
  onToggle,
  removeIcon,
}: SimpleAccordionProps) => {
  const [value, setIsValue] = useState<string>(isOpen ? title : '');

  useEffect(() => {
    setIsValue(isOpen ? title : '');
  }, [isOpen, title]);

  const onAccordionOpenHandler = (): void => {
    const nextOpen = isTruthy(value?.length) ? false : true;
    if (onToggle) {
      onToggle(nextOpen);
    } else {
      setIsValue(nextOpen ? title : '');
    }
  };

  return (
    <Accordion.Root
      type="single"
      value={value}
      className={classNames('AccordionRoot', className, { [styles.sidenavLayout]: layoutType === 'sidenav' })}
      collapsible
      onValueChange={onAccordionOpenHandler}
    >
      <Accordion.Item value={title} className={styles.item}>
        <SimpleAccordionTrigger
          image={image}
          title={title}
          value={value}
          hasCheckbox={hasCheckbox}
          setStateLayoutType={layoutType === 'sidenav' ? 'sidenav' : 'main'}
          containerClassName={triggerWrapperClassName}
          handleOpen={onAccordionOpenHandler}
          removeIcon={removeIcon}
        />
        <SimpleAccordionContent
          setStateLayoutType={layoutType === 'sidenav' ? 'sidenav' : 'main'}
          containerClassName={contentWrapperClassName}
          isInnerHtmlContent={isInnerHtmlContent}
        >
          {children}
        </SimpleAccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  );
};
