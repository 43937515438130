import * as Accordion from '@radix-ui/react-accordion';

import { Icon, IconsEnum } from 'components/Icons';

import Image from 'next/image';
import { IppicaNazioneImageDto } from 'types/swagger';
import React from 'react';
import { SimpleCheckbox } from 'components/inputs/simpleCheckbox';
import styles from './SimpleAccordionTrigger.module.scss';

export type SimpleAccordionTriggerProps = {
  text?: string;
  title: string;
  value: string;
  image?: IppicaNazioneImageDto; // ImageProps & { srcFallback: string };
  hasCheckbox?: boolean;
  setStateLayoutType?: SimpleAccordionTriggerLayoutType;
  containerClassName?: string;
  handleOpen: React.Dispatch<React.SetStateAction<string>>;
  removeIcon?: boolean;
};

export type SimpleAccordionTriggerLayoutType = 'main' | 'sidenav';

// eslint-disable-next-line react/display-name
export const SimpleAccordionTrigger = React.forwardRef<HTMLButtonElement, SimpleAccordionTriggerProps>(
  ({ ...props }, forwardedRef) => {
    const { title, text, hasCheckbox, setStateLayoutType, image, value, handleOpen, removeIcon } = props;

    const handleChangeCheckbox = () => {
      handleOpen(value);
    };

    const renderImage = () => {
      const { src, alt, title, srcFallback } = image ?? {};

      const imgSrc = src ?? srcFallback;

      if (imgSrc) {
        return (
          <div className={styles.imageContainer}>
            <Image src={imgSrc} alt={alt ?? ''} title={title} className={styles.imageCountry} fill />
          </div>
        );
      }

      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <React.Fragment />;
    };

    return (
      <Accordion.Header
        className={`${styles.container} ${setStateLayoutType === 'sidenav' ? styles.sidenavLayout : ''}`}
      >
        <Accordion.Trigger
          className={`${styles.button} ${props.containerClassName ? props.containerClassName : ''}`}
          ref={forwardedRef}
        >
          {hasCheckbox && (
            <SimpleCheckbox
              value={'label'}
              checked={props.value !== ''}
              className={styles.checkbox}
              handleFilterChange={handleChangeCheckbox}
            />
          )}
          {renderImage()}
          {title}
          {text && <span className={styles.text}>{text}</span>}
          {!hasCheckbox && !removeIcon && <Icon iconId={IconsEnum.CHEWRON_DOWN} className={styles.icon} />}
        </Accordion.Trigger>
      </Accordion.Header>
    );
  }
);
